import { useEffect, useRef, useState } from 'react'
import { deleteVotes, fetchVotes, submitVotes } from '../helpers/votes_api'


const useVotesApi = ({ source, limit = 10 }) => {

    const [fetchedVotes, setFetchedVotes] = useState({
        items: [],
        meta: { total: 0, skip: 0, limit: 0 },
    })
    const [error, setError] = useState(null)
    const [errors, setErrors] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [numOfFetch, setNumOfFetch] = useState(0)
    const mountedRef = useRef(true)
    const redirectDueToExpiredSession = () => {
        alert("Session expired, please login again")
        window.location = window.location.protocol + "//" + window.location.host + "/"
        mountedRef.current = false
    }

    useEffect(() => {
        const fetchData = async () => {
            if (!mountedRef.current) return null
            setIsLoading(true)
            const { items, meta, error } = await fetchVotes({
                skip: 0, limit, source
            })
            setFetchedVotes({ items, meta })
            setError(error)
            setIsLoading(false)
        }
        fetchData()
    }, [source, limit, numOfFetch])

    const handleSubmitVotes = async ({
        number_of_polling_stations,
        data_source,
        candidate_votes = []
    }) => {
        setIsLoading(true)
        try {
            const { data } = await submitVotes({
                number_of_polling_stations,
                data_source,
                candidate_votes
            })
            return { data }
        } catch (error) {
            if (error.response && error.response.data.detail) {
                if (Array.isArray(error.response.data.detail)) {
                    setErrors(error.response.data.detail.map(e => `${e.loc[e.loc.length - 1]} ${e.msg}`))
                } else {
                    setError(error.response.data.detail)
                }
            }
            if (error.response.status === 401) {
                redirectDueToExpiredSession()
            }
            throw error
        } finally {
            if (mountedRef.current) {
                setIsLoading(false)
                setNumOfFetch(numOfFetch + 1)
            }
        }
    }

    const handleDeleteVotes = async (id) => {
        setIsLoading(true)
        try {
            const { data } = await deleteVotes(id)
            return { data, error: null}
        } catch (error) {
            setError(error)
            if (error.response.status === 401) {
                redirectDueToExpiredSession()
            }
            return { data: null, error }
        } finally {
            if (mountedRef.current) {
                setIsLoading(false)
                setNumOfFetch(numOfFetch + 1)
            }
        }
    }

    // Convert data format to follow previous implementation on firebase
    const data = fetchedVotes.items.map(item => {
        const candidates = {}
        item.candidate_votes.forEach(candidate => {
            candidates[candidate.code] = candidate.number_of_votes
        })
        return {
            id: item.id,
            numOfTPS: item.number_of_polling_stations,
            source: item.data_source,
            time: new Date(item.created_at),
            totalVotes: item.total_voters,
            ...candidates
        }
    })

    return {
        isLoading,
        data,
        submitVotes: handleSubmitVotes,
        deleteVotes: handleDeleteVotes,
        meta: fetchedVotes.meta,
        error,
        errors
    }
}

export { useVotesApi }