import React, { useState } from 'react'
import { Grid, Button, Icon, Modal } from 'semantic-ui-react';
import VoteSubmissionForm from '../components/Update';
import sources from '../consts/sources';
import VotesTable from '../components/VotesManagement';
import { useVotesApi } from '../hooks/votes_api';

export default function Admin({ handleLogout }) {
    const logout = async () => {
        handleLogout()
    }
    const sources_values = Object.keys(sources).map(key => sources[key]);
    const sourcesAPI = {}
    sources_values.forEach(source => {
        sourcesAPI[source] = useVotesApi({ source: source, limit: 10 })
    })
    const { isLoading, submitVotes, error, data, deleteVotes, errors } = useVotesApi({ limit: 10 })
    const [modalState, setModalState] = useState(false)

    return (
        <Grid stackable padded columns='equal'>
            <Grid.Row >
                <Grid.Column>
                    <Modal
                        open={modalState}
                        onClose={() => setModalState(false)}
                        onOpen={() => setModalState(true)}
                        trigger={<Button floated="left" color="green" animated="vertical">
                            <Button.Content hidden>Submit</Button.Content>
                            <Button.Content visible>
                                <Icon name='add' />
                            </Button.Content>
                        </Button>}
                    >
                        <VoteSubmissionForm
                            color="green"
                            isLoading={isLoading}
                            submitVotes={submitVotes}
                            error={error}
                            errors={errors}
                        ></VoteSubmissionForm>
                    </Modal>
                </Grid.Column>
                <Grid.Column>
                    <Button floated="right" color="blue" animated="vertical" onClick={logout}>
                        <Button.Content hidden>Logout</Button.Content>
                        <Button.Content visible>
                            <Icon name='sign-out' />
                        </Button.Content>
                    </Button>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row >
                <Grid.Column>
                    <VotesTable votesData={data} deleteVotes={deleteVotes} ></VotesTable>
                </Grid.Column>
            </Grid.Row>

        </Grid>
    )
}