import React from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Login from "./pages/Login"
import Admin from './pages/Admin';
import Public from './pages/Public';
import { Dimmer, Loader, Image, Grid } from 'semantic-ui-react';
import useAuth from './hooks/auth';


function App() {

	const { authState, login, logout } = useAuth();
	const authed = authState.authed;
	const loading = authState.loading;

	return !loading ? (
		<Router>
			<div>
				<Switch>
					<PublicRoute authed={authed} path="/login" component={Login} handleLogin={login} />
					<PrivateRoute authed={authed} path="/admin" component={Admin} handleLogout={logout} />
					<Route path="/" exact component={Public} />
				</Switch>
			</div>
		</Router>
	) : (
		<Grid>
			<Dimmer active inverted>
				<Loader inverted content='Loading' />
			</Dimmer>
			<Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
		</Grid>
	);
}

function PrivateRoute({ component: Component, authed, handleLogout, ...rest }) {
	return (
		<Route
			{...rest}
			render={props => authed === true
				? <Component {...props} handleLogout={handleLogout}></Component>
				: <Redirect to={{ pathname: '/login', state: { from: props.location } }}></Redirect>
			}
		></Route>
	)
}

function PublicRoute({ component: Component, authed, handleLogin, ...rest }) {
	return (
		<Route
			{...rest}
			render={props => authed === false
				? <Component {...props} handleLogin={handleLogin}></Component>
				: <Redirect to='/admin'></Redirect>
			}
		></Route>
	)
}

export default App;
