import React, { useState } from 'react';
import { Grid, Segment, Header, Form, Button, Message } from 'semantic-ui-react'


export default function Login({handleLogin}){
    const [username, setUsername] = useState("") 
    const [password, setPassword] = useState("")
    const [message, setMessage] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const login = async () => {
        setIsLoading(true);
        try {
            handleLogin(username, password)
        } catch (error) {
            setMessage(error.message)
        }
        setIsLoading(false);

    }

    return (
        <Grid stackable padded centered columns={2} verticalAlign="middle">
            <Grid.Column width={5}>
                <Header as='h2' textAlign="center">Pemilu </Header>
                {message ? <Message>{message}</Message> : <></>}
                <Segment>
                    <Form>
                        <Form.Input
                            fluid
                            icon="user"
                            iconPosition="left"
                            placeholder="User name"
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                        />
                        <Form.Input
                            fluid
                            icon="lock"
                            iconPosition="left"
                            placeholder="Password"
                            type="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                        <Button 
                            loading={isLoading} 
                            color="blue" 
                            fluid 
                            size="large" 
                            onClick={login}>Login</Button>
                    </Form>
                </Segment>
            </Grid.Column>
        </Grid>
    )
}