import React from 'react';
import {Card, Loader, Dimmer} from 'semantic-ui-react'
import {Line} from 'react-chartjs-2'
// import { db } from '../consts/firebase';
import * as moment from'moment';
import constanta from '../consts/consts';
import { candidates } from '../consts/candidates';


export default function LineGraph({votes, link, title, onClick = ()=>{}}) {
    let {isLoading} = votes;
    let {data} = votes;

    const candidateWithVotes = candidates.map((candidate)=>({
        ...candidate, 
        vote_series: data.map(
                doc=>{
                    const docData = doc
                    const numVotes = parseInt(docData[candidate.code])
                    const percentage = (numVotes/docData.totalVotes)*100
                    return percentage.toFixed(2)
                }
            ).reverse() 
    }))
    // Sort candidate by greatest votes to lowest
    candidateWithVotes.sort((a,b)=>a.vote_series[0]-b.vote_series[0])
    const datasets = candidateWithVotes.map(c=>({
        label                    : c.name,
        fill                     : true,
        lineTension              : 0.5,
        backgroundColor          : c.color,
        borderColor              : c.color,
        borderCapStyle           : 'butt',
        borderDash               : [],
        borderDashOffset         : 0.0,
        borderJoinStyle          : 'miter',
        pointBorderColor         : "#fff",
        // pointBackgroundColor     : "#2a2f37",
        pointBorderWidth         : 2,
        pointHoverRadius         : 6,
        pointHoverBackgroundColor: c.color,
        pointHoverBorderColor    : "#fff",
        pointHoverBorderWidth    : 2,
        pointRadius              : 4,
        pointHitRadius           : 5,
        data                     : c.vote_series,
        spanGaps                 : false
    }))

    const chart_data = {
        labels  : data.map(doc=>moment(doc.time).format(constanta.DATE_TIME_FORMAT)).reverse(),
        datasets: datasets,
    };

    const options = {
        tooltips: {
            enabled: true,
        },
        legend  : {display: false},
        scales  : {
            xAxes     : [ {
                display  : false,
                ticks    : {fontSize: '11', fontColor: '#969da5'},
                gridLines: {color: 'rgba(0,0,0,0.0)', zeroLineColor: 'rgba(0,0,0,0.0)'}
            } ], 
            yAxes     : [ {
                display: false, 
                ticks: {beginAtZero: true, max:100},
                max:100
            } ]
        }
    }


    return (
        <Card fluid onClick={()=>onClick()}>
            <Card.Content as="h3">
                <Card.Header>
                    {title}
                </Card.Header>
            </Card.Content>
            {isLoading ?
                <Dimmer active >
                    <Loader></Loader>
                </Dimmer>
            :        
                <Line data={chart_data} options={options}></Line>
            }
        </Card>
    )
}