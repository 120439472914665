import { useState, useEffect } from "react"
import { login, validateToken } from "../helpers/auth"

const useAuth = () => {
    const token = localStorage.getItem("access_token")
    const [authState, setAuthState] = useState({
        authed: token ? true : false,
        loading: false,
        token: token,
        err: null
    })

    const setToken = (token) => {
        localStorage.setItem("access_token", token)
        setAuthState({
            authed: true,
            loading: false,
            token: token
        })
    }

    const handleLogin = async (email, password) => {
        setAuthState({ ...authState, loading: true })
        try {
            const { access_token } = await login(email, password)
            setToken(access_token)
        } catch (err) {
            setAuthState({ ...authState, loading: false, err })
        }

    }

    const handleLogout = async () => {
        localStorage.removeItem("access_token")
        setAuthState({
            authed: false,
            loading: false,
            token: null
        })
    }

    useEffect(() => {
        const chekToken = async () => {
            const { error } = await validateToken(token)
            if (error) {
                localStorage.removeItem("access_token")
                setAuthState({
                    authed: token ? true : false,
                    loading: false,
                    token: token
                })
            } else {
                setAuthState({
                    authed: token ? true : false,
                    loading: false,
                    token: token
                })
            }
        }
        chekToken()
    }, [token])

    return {
        authState,
        setToken,
        login: handleLogin,
        logout: handleLogout
    }

}

export default useAuth