import React from 'react';
import { Table, Icon, Image, TableRow, TableBody, Label, Statistic, Popup, Modal, Button } from 'semantic-ui-react';
import * as moment from 'moment';
import { candidates } from '../consts/candidates';
import constanta from '../consts/consts';

export default function VotesTable({ votesData, deleteVotes }) {
    const [modalDelete, setModalDelete] = React.useState(false)
    const [selectedId, setSelectedId] = React.useState(null)
    return (
        <Table singleLine striped>
            <TableBody>
                {
                    votesData.map(doc => {
                        return (
                            <TableRow key={doc.id}>
                                <Table.Cell>
                                    <Label ribbon color='blue' image>
                                        <Icon name='globe' />
                                        {doc.source}
                                    </Label>
                                </Table.Cell>
                                <Table.Cell>
                                    <Statistic size='mini'>
                                        <Statistic.Value>{parseInt(doc.numOfTPS).toLocaleString("id")}</Statistic.Value>
                                        <Statistic.Label>TPS</Statistic.Label>
                                    </Statistic>
                                </Table.Cell>

                                {
                                    candidates.map((c, index) => (
                                        <Table.Cell key={c.id}>
                                            <Statistic key={index} size="mini">
                                                <Statistic.Value>
                                                    <Image  src={c.code + '.png'} inline circular />
                                                    <span>{parseInt(doc[c.code]).toLocaleString('id')} </span>
                                                </Statistic.Value>
                                                <Statistic.Label>{c.name}</Statistic.Label>
                                            </Statistic>
                                        </Table.Cell>
                                    ))
                                }
                                <Table.Cell>
                                    <Popup
                                        trigger={
                                            <Statistic size='mini'>
                                                {moment(doc.time).calendar()}
                                            </Statistic>
                                        }
                                        content={moment(doc.time).format(constanta.DATE_TIME_FORMAT)}
                                        position='top right'
                                    />

                                </Table.Cell>
                                <Table.Cell>
                                    <Modal
                                        trigger={<Button onClick={() => setSelectedId(doc.id)} basic size='tiny' color='red' icon='trash' />}
                                        open={modalDelete}
                                        onClose={() => setModalDelete(false)}
                                        onOpen={() => setModalDelete(true)}
                                        basic
                                        dimmer='blurring'
                                    >
                                        <Modal.Header>Konfirmasi</Modal.Header>
                                        <Modal.Content>
                                            Apakah anda yakin ingin menghapus data ini {selectedId}?
                                        </Modal.Content>
                                        <Modal.Actions>
                                            <Button 
                                                color='red' 
                                                onClick={() => {
                                                    if (selectedId) deleteVotes(selectedId)
                                                    setModalDelete(false)
                                                } }
                                            >Hapus</Button>
                                            <Button onClick={() => setModalDelete(false)}>Batal</Button>
                                        </Modal.Actions>
                                    </Modal>
                                </Table.Cell>
                            </TableRow>
                        )
                    })
                }

            </TableBody>

        </Table>


    )
}