import React from 'react';
import { Card, Loader, Dimmer } from 'semantic-ui-react';
import { HorizontalBar } from 'react-chartjs-2';
import sources from '../consts/sources';
import consts from '../consts/consts'
import { candidates } from '../consts/candidates';

export default function Summary({ votes }) {
    let sources_name = Object.values(sources)
    let isReady = false

    sources_name.forEach(x => { isReady = isReady || votes[x].isLoading })

    if (isReady)
        return (
            <Card fluid  >
                <Card.Content>
                    <Dimmer active inverted>
                        <Loader></Loader>
                    </Dimmer>
                </Card.Content>
            </Card>
        )

    let lastestVotes = sources_name
        .filter(x => votes[x].data.length > 0)
        .map(name => votes[name].data[0])
    const progresses = lastestVotes.map(x => parseInt(x.numOfTPS) / consts.TOTAL_TPS)
    const percentages = candidates.map(
        c => lastestVotes.map(
            (x, i) => parseInt(x[c.code]) * progresses[i]
                / parseInt(x.totalVotes)
        )
    )

    const datasets = candidates.map((c, i) => ({
        label: c.name,
        backgroundColor: c.color,
        borderWidth: 0,
        data: percentages[i],
    }))

    const data = {
        labels: sources_name,
        datasets: datasets
    };

    const options = {
        // maintainAspectRatio:false,
        responsive: true,
        scales: {
            xAxes: [{
                stacked: true,
                display: true,
                font: { size: 5 },
                ticks: {
                    min: 0,
                    max: 1,
                    callback: function (value, index, values) {
                        return `${value * 100}%`;
                    },
                },
            }],
            yAxes: [{
                stacked: true,
                display: false,
                ticks: {
                    beginAtZero: true
                }
            }]
        },

        tooltips: {
            enabled: true,
            callbacks: {
                label: function (tooltipItems, data) {
                    const candidateName = data.datasets[tooltipItems.datasetIndex].label
                    const sourceName = tooltipItems.yLabel
                    const latestVote = lastestVotes[tooltipItems.index]
                    const candidateCode = candidates.find(c => c.name === candidateName).code
                    const totalVotes = latestVote.totalVotes
                    const candidateData = latestVote[candidateCode]/totalVotes
                    const candidatePercentage = (candidateData * 100).toFixed(1)

                    return  `Perolehan Suara ${candidateName} ` +
                            `dari ${sourceName} ` +
                            `sebesar ${candidatePercentage.toLocaleString('id')} %`;
                }
            }
        }
    }

    return (
        <Card fluid >
            <Card.Content>
                <Card.Header>Total & Progress Perolehan Suara Tiap Sumber</Card.Header>
            </Card.Content>
            <HorizontalBar data={data} options={options} />
        </Card>
    )
}