import React from 'react'
import {Segment, Header} from 'semantic-ui-react'
import { useTimer } from 'react-timer-hook'

export default function CountDown(){

    let t = new Date(2024, 3, 20);
    const {
        seconds,
        minutes,
        hours,
        days,
      } = useTimer({ expiryTimestamp:t, onExpire: () => console.warn('onExpire called') });

    return (
        <Segment >
            <Header sub textAlign="right">REAL COUNT PENGUMUMAN RESMI KPU</Header>
            <Header style={{lineHeight:0}} as="h2" textAlign="justified" >
                    {days} : {hours} : {minutes} : {seconds}
            </Header>
            <Header style={{lineHeight:0, fontWeight:"lighter"}} color="grey" sub textAlign="justified">hari jam menit detik</Header>
        </Segment>
    )
}