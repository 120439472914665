const candidates = [
    {
        id: 1,
        name: 'Anies - Muhaimin',
        president: 'Anies Baswedan',
        vice: 'Muhaimin Iskandar',
        code: 'anies_muhaimin',
        color: '#84FF63',
        color_name: 'green',
        kpu_code: '100025'
    },
    {
        id: 2,
        name: 'Prabowo - Gibran',
        president: 'Prabowo Subianto',
        vice: 'Gibran Rakabuming Raka',
        code: 'prabowo_gibran',
        color: '#6384FF',
        color_name: 'blue',
        kpu_code: '100026'
    },
    {
        id: 3,
        name: 'Ganjar - Mahfud',
        president: 'Ganjar Pranowo',
        vice: 'Mahfud MD',
        code: 'ganjar_mahfud',
        color: '#FF6384',
        color_name: 'red',
        kpu_code: '100027'
    }
]

export {candidates};