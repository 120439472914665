
import React from "react"
import KPU from "../hooks/kapeu";
import { Icon, Segment, Popup, Grid, GridColumn, Progress } from "semantic-ui-react";

const wrapperStyles = {
  width: "100%",
  maxWidth: 980,
  margin: "0 auto",
}

export default function Map(){
    let {isLoading, data:kpuData} = KPU() 

    if (isLoading) return(<div>loading</div>)



    return (
        <div style={wrapperStyles}>
            <Segment style={{backgroundColor:'#ecf0f1'}}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 795 350">
                {kpuData.map(kpu=>{
                  const {percentage, province, votes} = kpu
                  if (!province) return (<></>)
                  const {id, path} = province

                  const voteValues = votes.map(v=>v.votes)
                  const maxVote = Math.max(...voteValues) 
                  const maxVoteIndex = voteValues.indexOf(maxVote)
                  const greatestVote = votes[maxVoteIndex]
                  const color_name = greatestVote ? greatestVote.color_name : 'black'

                  return (
                    <Popup
                      size="small"
                      key={id} 
                      trigger={
                        <path 
                          fill={color_name} 
                          opacity={percentage/100}
                          d={path}
                        ></path>
                      }
                    >
                      <Popup.Header>{province.title}</Popup.Header>
                      <Popup.Content>
                        <Grid divided columns='equal'>
                          {votes.length > 0 ? votes.map(vote=>{
                            return (
                              <GridColumn key={vote.name}>
                                <Grid.Row>
                                  <Grid.Column>
                                    <Icon name="circle outline" color={vote.color_name}></Icon>
                                  </Grid.Column>
                                  <Grid.Column>
                                    {vote.name}
                                  </Grid.Column>
                                  <Grid.Column>
                                    {vote.votes.toLocaleString('id')}
                                  </Grid.Column>
                                </Grid.Row>
                              </GridColumn>
                            )
                          }): <>
                            <GridColumn>
                              <Grid.Row>
                                <Grid.Column>
                                  <Icon name="circle outline" color="black"></Icon>
                                </Grid.Column>
                                <Grid.Column>
                                  Tidak ada data
                                </Grid.Column>
                              </Grid.Row>
                            </GridColumn>
                          </>
                          }
                        </Grid>
                        <Grid divided columns='equal'>
                          <GridColumn>
                            <Grid.Row>
                              {percentage ? <Progress color={color_name} percent={percentage} size='tiny'>
                                Progress: {percentage}% 
                              </Progress> : <></>}
                            </Grid.Row>
                          </GridColumn>
                        </Grid>
                      </Popup.Content>
                    </Popup>)
                })}
              </svg>
            </Segment>
        </div>
      )
}

