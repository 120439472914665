import React from 'react';
import {Card, Feed, Loader, Segment, Image, Dimmer} from 'semantic-ui-react'
import * as moment from 'moment' 
import constanta from '../consts/consts';
import { candidates } from '../consts/candidates';

/**
 * 
 * @param {String} source
 */
export default function Activites({votes, title}){

    return (
        votes.isLoading
        ? 
        <Segment>
            <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
            </Dimmer>

            <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
        </Segment>
        :
        <Card fluid>
            <Card.Content>
                <Card.Header>Pembaruan</Card.Header>
                <Card.Description >{title}</Card.Description>

            </Card.Content>
            <Card.Content>
                <Feed>
                    {votes.data.map(doc=>
                        <Feed.Event key={doc.id} >
                            <Feed.Label image='https://react.semantic-ui.com/images/avatar/small/elliot.jpg' />
                            <Feed.Content>
                                <Feed.Date key={doc.id} content={moment(doc.time).format(constanta.DATE_TIME_FORMAT)} />
                                <Feed.Summary>
                                    {candidates.map(candidate=><p key={candidate.id}>Perolehan suara paslon {candidate.id} : {parseInt(doc[candidate.code]).toLocaleString('id')}</p>)}
                                </Feed.Summary>
                            </Feed.Content>
                        </Feed.Event>
                    ).slice(0,2)}
                </Feed>
            </Card.Content>
        </Card>
    )

}