import {useEffect, useState} from 'react'
import { candidates } from '../consts/candidates';
import axios from 'axios';
import provinces from '../consts/provinces';
export default function KPU(){
    const [kpu, setKpu] = useState({
        isLoading: true,
        data: {}
    })

    const KPU_URL = "https://hasil2024.id/kpu-data"

    useEffect(()=>{
        const fetchKPU = async () => {
            try {
                let {data} = await axios.get(KPU_URL)
                if (data.hasOwnProperty('table')) { 
                    const table = Object.keys(data.table).map(key=>{
                        const row = data.table[key]
                        const votes = Object.keys(row).map(col=>{
                            const candidate = candidates.find(c=>c.kpu_code===col)
                            if (candidate) {
                                return {
                                    ...candidate,
                                    votes: row[col]
                                }
                            }
                            return false
                        }).filter(v=>v)
                        const province = provinces.find(p=>p.kode===key)
                        return {province, votes, percentage: row['persen']}
                    })
                    setKpu({
                        isLoading: false,
                        data: table
                    })
                }
            } catch (error) {
                console.error(error)
            }
        }
        fetchKPU()
    }, [])

    return kpu
}