import config from "../config"
import axios from "axios"

const url = `${config.API_BASE_URL}/votes/`

export const fetchVotes = async ({ skip = 0, limit = 10, ...queryParams }) => {
    try {
        const response = await axios.get(url, {
            params: {
                skip,
                limit,
                ...queryParams
            }
        })
        const { data } = response
        const { items, meta } = data
        return { items, meta, error: null }
    } catch (error) {
        return { error, items: [], meta: { total: 0, skip: 0, limit: 0 } }
    }
}

export const submitVotes = async ({
    number_of_polling_stations,
    data_source,
    candidate_votes = []
}) => {
    const headers = {
        accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    }
    const response = await axios.post(url, {
        number_of_polling_stations,
        data_source,
        candidate_votes
    }, { headers })
    const { data } = response
    return data
}

export const deleteVotes = async (id) => {
    const headers = {
        accept: 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('access_token')
    }
    const response = await axios.delete(`${url}${id}`, { headers })
    const { data } = response
    return { data }
}
