import React from 'react'
import { Grid, Image, Header, Card } from 'semantic-ui-react';
import Activites from '../components/Activities';
import LineGraph from '../components/LineGraph';
import sources from '../consts/sources';
import CountDown from '../components/CountDown';
import Percentage from '../components/Percentage';
import Summary from '../components/Summary';
import { useVotesApi } from '../hooks/votes_api';
import constanta from '../consts/consts';
import Map from '../components/Map';

export default function Public() {
    

    const votesAPI = {}
    Object.keys(sources).forEach(source => {
        votesAPI[sources[source]] = useVotesApi({ source: sources[source], limit: 10 })
    })

    return (
        <Grid stackable columns="equal" padded >

            <Grid.Row>
                <Grid.Column width={4} textAlign="center">
                    <Image style={{ paddingTop: '14px' }} src="/logo.png" size="small" href="/"></Image>
                </Grid.Column>

                <Grid.Column width={4} floated="right">
                    <CountDown></CountDown>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row only="mobile">
                <Grid.Column width={8}>
                    <Card fluid>
                        <Image src="/header.png"></Image>
                    </Card>
                    <Summary style={{ heiht: '100%' }} votes={votesAPI}></Summary>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column>
                    <Map></Map>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column>
                    <LineGraph
                        link={constanta.KPU_WEBSITE}
                        votes={votesAPI[sources.KPU]}
                        title="KPU"
                    ></LineGraph>
                    <Percentage
                        votes={votesAPI[sources.KPU]}
                        title="Komisi Pemilihan Umum"
                    ></Percentage>
                    <Activites
                        votes={votesAPI[sources.KPU]}
                        title="Komisi Pemilihan Umum"
                    ></Activites>
                </Grid.Column>
                <Grid.Column>
                    <LineGraph
                        link="http://kawalpemilu.org"
                        votes={votesAPI[sources.KAWAL_PEMILU]}
                        title="Kawal Pemilu"
                    ></LineGraph>
                    <Percentage
                        votes={votesAPI[sources.KAWAL_PEMILU]}
                        title="Kawal Pemilu"
                    ></Percentage>
                    <Activites
                        votes={votesAPI[sources.KAWAL_PEMILU]}
                        title="Kawal Pemilu"
                    ></Activites>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Card fluid>
                        <Image src="/header.png"></Image>
                    </Card>
                    <Summary style={{ heiht: '100%' }} votes={votesAPI}></Summary>
                </Grid.Column>
            </Grid.Row>


            <Grid.Row centered columns="one">
                <Grid.Column width={9} textAlign="center">
                    <p>Data yang ditampilkan berasal dari web masing-masing. <a href='/'>hasil2024.id</a> merupakan agregasi yang melakukan fetching data berkala dan menampilkan ke dalam bentuk visual komparatif</p>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered columns="one">
                <Grid.Column width={3} textAlign="center">
                    <Header>Powered by</Header>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row centered columns="one">
                <Grid.Column width={4} textAlign="center">
                    <Image size="big" href="https://idcloudhost.com/" src="https://idcloudhost.com/wp-content/uploads/2016/05/idCloudHost-Logo-horizontal-01.png" ></Image>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}