const dev = {
    "API_BASE_URL": "http://localhost:8000",
}

const prod = {
    "API_BASE_URL": "https://hasil2024.id",
}

const config = {
    ...(process.env.REACT_APP_STAGE === 'production' ? prod : dev)
}

export default config