import React, { useEffect, useState } from 'react'
import { Segment, Header, Form, Button, Message } from 'semantic-ui-react'
import { candidates } from '../consts/candidates'
import sources from '../consts/sources'

export default function VoteSubmissionForm({
    color,
    isLoading,
    submitVotes,
    error,
    errors = [],
}) {
    const [message, setMessage] = useState(error)
    const [messageList, setMessageList] = useState(errors)
    const [messageColor, setMessageColor] = useState('red')

    const candidates_vote = candidates.reduce((acc, candidate) => {
        acc[candidate.id] = '';
        return acc;
    }, {});
    const [candidateVotes, setCandidateVotes] = useState(candidates_vote)
    const [numOfPollingStation, setNumOfPollingStation] = useState('')
    const [source, setSource] = useState('')

    useEffect(() => {
        setMessage(error)
        setMessageList(errors)
    }, [error, errors])

    const handleSubmit = async () => {
        const dataSubmission = {
            data_source: source,
            candidate_votes: Object
                .keys(candidateVotes)
                .map(candidateId => [
                    candidateId,
                    parseInt(candidateVotes[candidateId])
                ]),
            number_of_polling_stations: numOfPollingStation,
        }
        try {
            await submitVotes(dataSubmission)
            setMessage("Data submitted")
            setMessageList([])
            setMessageColor("green")
            setCandidateVotes(candidates_vote)
            setNumOfPollingStation(0)
        } catch (err) {
            setMessageColor("red")
        }
    }

    return (
        <Segment color={color}>
            <Header>Votes Data Submission</Header>
            {message || messageList.length
                ? <Message
                    list={messageList}
                    onClick={() => {
                        setMessage('');
                        setMessageList([]);
                    }}
                    color={messageColor}
                    header={message}
                ></Message>
                : ''
            }
            <Form>
                {candidates.map((candidate, index) =>
                    <Form.Input
                        key={index}
                        fluid
                        label={candidate.name}
                        type="number"
                        value={candidateVotes[candidate.id]}
                        onChange={e => setCandidateVotes({
                            ...candidateVotes,
                            [candidate.id]: parseInt(e.target.value),
                        })}
                    ></Form.Input>
                )}
                <Form.Dropdown
                    fluid
                    label="Data Source"
                    onChange={e => setSource(
                        e.target.textContent
                    )}
                    value={source}
                    selection
                    placeholder="Data Source"
                    options={
                        Object.keys(sources).map(key => ({
                            key,
                            text: sources[key],
                            value: sources[key]
                        }))
                    }
                />
                <Form.Input
                    fluid
                    label="Jumlah TPS"
                    type="number"
                    onChange={
                        e =>
                            setNumOfPollingStation(
                                parseInt(e.target.value)
                            )
                    }
                    value={numOfPollingStation}
                ></Form.Input>

                <Button loading={isLoading} onClick={handleSubmit} color="blue">Submit</Button>
            </Form>
        </Segment>
    )
}