import React from 'react'
import { Card, Loader, Dimmer, Statistic, Image } from 'semantic-ui-react';
import { Doughnut } from 'react-chartjs-2';
import * as moment from 'moment'
import constanta from '../consts/consts';
import { candidates } from '../consts/candidates';

export default function Percentage({votes, title}){

    if (votes.isLoading) {
        return(
            <Card>
                <Card.Content>
                    <Dimmer active>
                        <Loader></Loader>
                    </Dimmer>
                </Card.Content>
            </Card>
        )
    }
    if (votes.data.length === 0) {
        return(
            <Card>
                <Card.Content>
                    <Card.Header>{title}</Card.Header>
                    <Card.Description>Belum ada data</Card.Description>
                </Card.Content>
            </Card>
        )
    }

    const latest_vote_data = votes.data[0]
    let {time} = latest_vote_data

    const candidates_vote = candidates.map(c => parseInt(latest_vote_data[c.code]))
    const total = candidates_vote.reduce((partialSum, a) => partialSum + a, 0);
    const percentages = candidates_vote.map(vote => parseFloat(((vote/total) * 100).toFixed(1)))
    const bgColor = candidates.map(c => c.color)
    const candidatesWithPercentage = candidates.map((c, index) => ({...c, percentage: percentages[index]}))

    const data = {
        labels: candidates.map(c => c.name),
        datasets: [{
            data: percentages,
            backgroundColor: bgColor,
            borderColor:'rgba(0,0,0,0)',
            hoverBackgroundColor: bgColor
        }]
    };

    const options = {
        tooltips: {enabled: true},
        legend  : {display: false},
        cutoutPercentage: 80,
        rotation: Math.PI * (0.75)
    }
    

    return (
        <Card fluid style={{backgroundColor:'#2c3e50'}}>
            <Card.Content >
                <Card.Header style={{color:'white'}} >{title}</Card.Header>
                <Card.Description style={{color:'white'}} >Perolehan suara hingga {moment(time).format(constanta.DATE_TIME_FORMAT)} </Card.Description>
            </Card.Content>
            <Card.Content>
                {candidatesWithPercentage.slice(0, 2).map((c, index) =>
                    <Statistic key={index} size="mini" inverted>
                        <Statistic.Value>
                            <Image style={{height: 25}} src={c.code + '.png'} inline circular />
                            {c.percentage} <span>&#37;</span>
                        </Statistic.Value>
                        <Statistic.Label>{c.name}</Statistic.Label>
                    </Statistic>
                )}
                <Doughnut options={options} data={data}></Doughnut>
                {candidatesWithPercentage.slice(2, 3).map((c, index) =>
                    <Statistic key={index} size="mini" inverted>
                        <Statistic.Value>
                            <Image style={{height: 25}} src={c.code + '.png'} inline circular />
                            {c.percentage} <span>&#37;</span>
                        </Statistic.Value>
                        <Statistic.Label>{c.name}</Statistic.Label>
                    </Statistic>
                )}
            </Card.Content>
        </Card>
    )
}