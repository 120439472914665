import axios from 'axios';
import config from '../config';
import qs from 'qs';


export async function login(email, pw) {
    const URL = `${config.API_BASE_URL}/auth/login`
    const headers = {
        accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded'
    }
    const data = qs.stringify({
        username: email,
        password: pw,
    }) 
    const response = await axios.post(URL, data, { headers })
    const { access_token, expire } = response.data
    return { access_token, expire }
}

export async function validateToken(token) {
    const URL = `${config.API_BASE_URL}/auth/me`
    const headers = {
        accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${token}`
    }
    try {
        await axios.get(URL, { headers })
        return { error: null }
    } catch (error) {
        return { error }
    }
}

